'use client'

import React from 'react'
import ErrorContent from './ErrorContent'
import * as Sentry from '@sentry/nextjs'

interface GlobalErrorProps {
    error: Error & { digest?: string }
}

/**
 * global-error wraps the entire application and is the last line of defense for errors.
 * 
 * https://nextjs.org/docs/app/building-your-application/routing/error-handling#handling-errors-in-root-layouts
 */
export default function GlobalError(error: GlobalErrorProps) {
    React.useEffect(() => {
        Sentry.captureException(error)
    }, [error])

    return (
        <html lang="en">
            <body>
                <ErrorContent />
            </body>
        </html>
    )
}
